import React from "react";
import "../App.css";
import { FaInstagram } from "react-icons/fa";
import ErikImage from "../images/erik_profile_1.png";

function Erik() {
  return (
    <div className="min-h-[100vh]">
      <div className="flex__layout">
        <div className="artist__description" data-aos="fade-down">
          <h1 className="text-4xl">Erik Steen</h1>
          <br />
          <h3 className="text-3xl italic">Songwriter / Producer</h3>
          <br />
          <p>
            Erik Steen is a songwriter and music producer from Gothenburg,
            Sweden. Best known for his production details and melody sense, his
            100 plus million streaming credits include artists Edward Maya, Twan
            Ray, Lucas Estrada, Lost Ways and more. Having been in the music
            industry for many years, he offers a great understanding of the
            industry and is known for his unique sound.
          </p>
          <br />

          <div className="social__media__icons">
            <li>
              <a
                href="https://www.instagram.com/eriicemusicofficial/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <p>
                  <FaInstagram fill="black" size={40} />
                </p>
              </a>
            </li>
          </div>
        </div>

        {/*Photos or Videos + playlist.*/}
        <div>
          <div className="__photos" data-aos="fade-up">
            <img src={ErikImage} className="artists__photo__size" />
          </div>
        </div>
      </div>

      <div className="flex justify-center py-10" data-aos="fade-in">
        <iframe
          src="https://open.spotify.com/embed/artist/3DVYnBKNeZTLLPvMaEbmzC?utm_source=generator&theme=0"
          className="w-[90%] max-w-[800px] h-[380px]"
          frameBorder="0"
          allowfullscreen=""
          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        ></iframe>
      </div>
    </div>
  );
}

export default Erik;
