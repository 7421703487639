import React from "react";
import "../App.css";
import { FaInstagram } from "react-icons/fa";
import TimImage from "../images/tim_profile_1.png";

function Tim() {
  return (
    <div className="min-h-[100vh]">
      <div className="flex__layout">
        <div className="artist__description" data-aos="fade-down">
          <h1 className="text-4xl">Tim Hedlund</h1>
          <br />
          <h3 className="text-3xl italic">Producer / Songwriter</h3>
          <br />
          <p>
            Tim Hedlund is a producer/songwriter from Stockholm, Sweden who
            focuses on creating music within the EDM/Pop/K-Pop genre. Having
            been in the music industry for 5 years, he offers a great
            understanding of the industry and is known for his perceptive,
            melodies and unique productions.
          </p>
          <br />
          <p>
            Some of he's recent cuts/holds include artists such as MC
            Laranjinha, Eddy Britto & Samuel, Nooon, Sofia Karlberg, Ella
            Rammelt, Reggie Mills, Egzod, Suark, Joe Kox.
          </p>
          <br />
        </div>

        {/*Photos or Videos + playlist.*/}
        <div>
          <div className="__photos" data-aos="fade-up">
            <img src={TimImage} className="artists__photo__size" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Tim;
