import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Header from "./components/Header";
import Artists from "./components/Artists";
import Footer from "./components/Footer";

import Elsa1 from "./pages/Elsa1.js";
import Adam from "./pages/Adam.js";
import Alexander from "./pages/Alexander.js";
import Elias from "./pages/Elias.js";
import About from "./pages/About.js";
import Start from "./pages/Start.js";
import Contact from "./pages/Contact";
import Gustav from "./pages/Gustav";
import Johanna from "./pages/Johanna";

import "./App.css";
import Work from "./pages/Work";
import Karl from "./pages/Karl";
import Steerner from "./pages/Steerner.js";
import Erik from "./pages/Erik.js";
import Jake from "./pages/Jake.js";
import Tim from "./pages/Tim.js";
import Studios from "./components/Studios.js";

function App() {
  return (
    <Router>
      <div>
        <Header />

        <Switch>
          <Route exact path="/">
            <Start />
          </Route>
          <Route path="/start">
            <Artists />
          </Route>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/contact">
            <Contact />
          </Route>
          <Route path="/elsa-carmona-oljelund">
            <Elsa1 />
          </Route>
          <Route path="/adam-woods">
            <Adam />
          </Route>
          <Route path="/alexander-latila">
            <Alexander />
          </Route>
          <Route path="/gustav-nilsson">
            <Gustav />
          </Route>
          <Route path="/elias-ohberg">
            <Elias />
          </Route>
          <Route path="/karl-flykt">
            <Karl />
          </Route>
          <Route path="/johanna-ekholm">
            <Johanna />
          </Route>
          <Route path="/niklas-sterner">
            <Steerner />
          </Route>
          <Route path="/erik-steen">
            <Erik />
          </Route>
          <Route path="/jake-neumar">
            <Jake />
          </Route>

          <Route path="/tim-hedlund">
            <Tim />
          </Route>

          <Route path="/work">
            <Work />
          </Route>

          <Route path="/studios">
            <Studios />
          </Route>
        </Switch>

        <Footer />
      </div>
    </Router>
  );
}

export default App;
