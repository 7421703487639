import React, { useEffect } from "react";

import AOS from "aos";
import "aos/dist/aos.css";

const TextBlock = () => {
  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
  }, []);

  return (
    <div className="mt-10 flex flex-col justify-center items-center">
      <div className="flex flex-col md:flex-row md:justify-evenly md:mr-5">
        <h1 className="text-7xl md:text-9xl" data-aos="flip-up">
          Writers.
        </h1>
      </div>
    </div>
  );
};

export default TextBlock;
