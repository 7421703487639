import React from "react";
import { useEffect } from "react";
import { StudioImages } from "../constants/Data.js";

import AOS from "aos";
import "aos/dist/aos.css";

const Studios = () => {
  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
  }, []);

  return (
    <div className="min-h-[100vh]">
      <div className="mt-10 flex flex-col justify-center items-center">
        <div className="flex flex-col md:flex-row md:justify-evenly">
          <h1 className="text-7xl md:text-9xl" data-aos="flip-up">
            Our Studios.
          </h1>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 container flex flex-col justify-center items-center mt-5 mb-10 px-5">
          {StudioImages.map((studio, index) => (
            <a
              key={index}
              className="text-center shadow-lg relative"
              data-aos="fade-in"
            >
              <img
                src={studio.imageUrl}
                alt={`Image ${index + 1}`}
                className="max-w-full"
                loading="lazy"
                width="600"
                height="500"
              />
              {/*
          <div className="text-2xl md:text-3xl absolute inset-0 flex justify-center items-center opacity-0 hover:opacity-100 duration-300 transition-opacity">
            <h6 className="text-white"> {writer.imageText}</h6>
          </div> */}
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Studios;
