import React from "react";
import "../App.css";
import { FaInstagram } from "react-icons/fa";
import JakeImage from "../images/jake_profile_1.png";

function Jake() {
  return (
    <div className="min-h-[100vh]">
      <div className="flex__layout">
        <div className="artist__description" data-aos="fade-down">
          <h1 className="text-4xl">Jake Neumar</h1>
          <br />
          <h3 className="text-3xl italic">Songwriter / Producer</h3>
          <br />
          <p>
            Jake Neumar is a songwriter, vocalist, and music producer in
            Nashville, Tennessee. Best known for his vocal prowess andmelody
            sense, his 25-plus million streaming credits include artists like
            Edward Maya, Daniel Allan, Mashbit and Syence, and labels such as
            Monstercat, Thrive, CloudKid, Lost In Dreams and many others.
          </p>
          <br />
          <p>
            His songwriting and production can also be heard in countless shows
            and films, including “Lucifer” and “Selling Sunset”, and on
            big-brand TV ads for companies like LG and NBC.
          </p>
          <br />

          <div className="social__media__icons">
            <li>
              <a
                href="https://www.instagram.com/jakeneumar/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <p>
                  <FaInstagram fill="black" size={40} />
                </p>
              </a>
            </li>
          </div>
        </div>

        {/*Photos or Videos + playlist.*/}
        <div>
          <div className="__photos" data-aos="fade-up">
            <img src={JakeImage} className="artists__photo__size" />
          </div>
        </div>
      </div>

      <div className="flex justify-center py-10" data-aos="fade-in">
        <iframe
          src="https://open.spotify.com/embed/artist/69weKTfYYEBofMntV36KPG?utm_source=generator&theme=0"
          className="w-[90%] max-w-[800px] h-[380px]"
          frameBorder="0"
          allowfullscreen=""
          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        ></iframe>
      </div>
    </div>
  );
}

export default Jake;
